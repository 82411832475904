export const logos = {
  clarity: {
    bw: require("./clarity/clarity-bw.png"),
    color: require("./clarity/clarity-color.png"),
  },
  smartFerti: {
    bw: require("./smart-ferti/smart-ferti-bw.png"),
    color: require("./smart-ferti/smart-ferti-color.png"),
  },
  christify: {
    bw: require("./christify/christify-bw.png"),
    color: require("./christify/christify-color.png"),
  },
  maritime: {
    bw: require("./maritime/maritime-bw.png"),
    color: require("./maritime/maritime-color.png"),
  },
  onTheGo: {
    bw: require("./on-the-go/on-the-go-bw.png"),
    color: require("./on-the-go/on-the-go-color.png"),
  },
  saveTheHero: {
    bw: require("./save-the-hero/save-the-hero-bw.png"),
    color: require("./save-the-hero/save-the-hero-color.png"),
  },
  doccierge: {
    bw: require("./doccierge/doccierge-bw.png"),
    color: require("./doccierge/doccierge-color.png"),
  },
  adpTruck: {
    bw: require("./adp-truck/adp-truck-bw.png"),
    color: require("./adp-truck/adp-truck-color.png"),
  },
  meaningfulMinute: {
    bw: require("./meaningful-minute/meaningful-minute-bw.png"),
    color: require("./meaningful-minute/meaningful-minute-color.png"),
  },


};
