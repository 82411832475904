import React from 'react';
import { Header } from './components/header';
import { useTranslation } from "react-i18next";
import { icons } from "../../assets/icons/icons";
import { bgImages } from "../../assets/images/bg-images";
import { CitiesMarquee } from "./components/cities-marquee";


interface BlockProps {
    icon: string;
    header: string;
    description: string;
    style?: React.CSSProperties;
}

const Block: React.FC<BlockProps> = ({ icon, header, description }) => {
    const background = bgImages.sectionBackground;
    return (
        <div className="max-w-[370px] min-h-[218px] border-gradient p-6" style={{ backgroundImage: `url(${background})`, width: '100%' }}>
            <div className="flex flex-row mb-4 items-center" style={{ width: '100%' }}>
                <img src={icon} alt="icon" style={{ width: '24px', height: '24px', marginTop: '0', marginBottom: 'auto' }} />
                <div className='pl-3 text-heading-6 text-primary-white-0 font-prosto-one' style={{ width: 'calc(100% - 24px)', lineHeight: '1.4' }}>{header}</div>
            </div>
            <ul style={{ listStyle: 'disc', paddingLeft: '20px', width: '100%' }} className='text-heading-8 max-w-80 text-primary-white-0'>
                {description.split(' | ').map((item: string, index: number) => (
                    <li key={index} style={{ width: '100%' }}>{item}</li>
                ))}
            </ul>
        </div>
    );
}

export const Perks = () => {
    const { t } = useTranslation();

    return (
        <div id='benefits' className="flex flex-col items-center justify-center px-4">
            <Header />
            <div className="gap-6 justify-center flex flex-row flex-wrap" style={{ width: '100%' }}>
                <Block
                    icon={icons.perks.block1}
                    header={t("perks.blocks.block1.header")}
                    description={t("perks.blocks.block1.description")}
                    style={{ width: '100%' }}
                />
                <Block
                    icon={icons.perks.block2}
                    header={t("perks.blocks.block2.header")}
                    description={t("perks.blocks.block2.description")}
                    style={{ width: '100%' }}
                />
                <Block
                    icon={icons.perks.block3}
                    header={t("perks.blocks.block3.header")}
                    description={t("perks.blocks.block3.description")}
                    style={{ width: '100%', alignSelf: 'center' }}
                />
            </div>
            <CitiesMarquee />
        </div>
    );
}
