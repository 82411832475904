import React, { useRef } from "react";
// @ts-expect-error //lorem lorem lorem
import heroVideo from "../../../../src/assets/videos/hero/hero.mp4";
import { icons } from "../../icons/icons";

interface HeroVidProps {
    videoExpanded: boolean;
    collapseVideo: () => void;
}

export const HeroVid: React.FC<HeroVidProps> = ({ videoExpanded, collapseVideo }) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const handleVideoClick = () => {
        if (videoRef.current) {
            collapseVideo()
            videoRef.current.currentTime = 0
        }
    };

    React.useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.play();
        }
    }, []);

    return (
        <div className="flex items-end" style={{
            opacity: 1,
            transition: "all 1s",
            gridColumnStart: 1,
            gridRowStart: 1,
        }}>
            <img src={icons.button.close} alt="close" onClick={handleVideoClick}
                className='absolute right-[40px] w-[40px] cursor-pointer
                 lg:top-[150px]
                 sm:top-[100px]
                 top-[90px]
                 z-10'
                style={{
                    opacity: videoExpanded ? 1 : 0,
                }}
            />
            <video
                style={{
                    transition: 'transform 1s ease, filter 500ms ease',
                    opacity: 1,
                    zIndex: videoExpanded ? 9 : 1,
                    position: 'relative',
                    // transform: videoExpanded ? 'scale(1)' : 'scale(0.22)',
                    margin: 'auto',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    filter: videoExpanded ? 'brightness(1)' : 'brightness(0.1)',
                }}
                className="w-[100vw]"
                autoPlay={false}
                loop
                onPause={collapseVideo}
                onClick={handleVideoClick}
                ref={videoRef}
                muted
            >
                <source src={heroVideo} type="video/mp4" />
            </video>
        </div>
    );
};


