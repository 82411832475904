import React, { useState } from "react";
import { logos } from "../../../../assets/images/logos/logos";
import { Logo } from "./logo";

export const Logos = () => {
    const [showMore, setShowMore] = useState(false);
    const logoKeys = Object.keys(logos) as Array<keyof typeof logos>;
    const initialLogosToShow = window.innerWidth < 600 ? 4 : logoKeys.length;

    const handleShowMore = () => {
        setShowMore(true);
    };

    const handleShowLess = () => {
        setShowMore(false);
    };

    return (
        <div className="mt-14 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 max-w-[916px] px-4 mx-auto gap-y-14 gap-x-12 [&>*:nth-child(4)]:w-[50%] [&>*:nth-child(4)]:mx-auto">
            {logoKeys.slice(0, showMore ? logoKeys.length : initialLogosToShow).map((logoKey, index) => (
                <Logo key={index} logoKey={logoKey} />
            ))}
            {window.innerWidth < 600 && !showMore && (
                <button onClick={handleShowMore}>View More</button>
            )}
            {showMore && (
                <div className='flex'>
                    <button onClick={handleShowLess}>View Less</button>
                </div>
            )}
        </div>
    );
};
