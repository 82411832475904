import React from 'react';
import { Header } from "./components/header";
import { Button } from "../../global-components/button/button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const ContactUs = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className='flex justify-center'>
            <div id='contactUs' className='w-[770px] p-8 flex flex-col mt-18 md:mt-20 lg:mt-30 text-center'
                style={{ background: 'linear-gradient(136.5deg, #2E2D2E -36.18%, #0B0A0E 83.23%)' }}>
                <Header />
                <div className='flex flex-col sm:flex-row gap-4 md:gap-8 w-full max-w-144 mx-auto justify-around'>
                    <Button
                        variant={"outlined"}
                        size={"large"}
                        onClick={() => {
                            navigate(`/contact-us-page`);
                            window.scrollTo(0, 0)
                        }}
                        title={t('contactUs.fButton')}
                        classes={'w-[50%]'}
                        forwardIcon
                    />
                    <a href="#pricing" className='w-[100%]'>
                        <Button
                            variant={"filled"}
                            size={"large"}
                            title={t('contactUs.sButton')}
                            classes={'w-full '}
                            forwardIcon
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};