import React from "react";
import {useTranslation} from "react-i18next";

export const Header = () => {
    const {t} = useTranslation();
    return (
        <header className="text-primary-white-0 flex items-center flex-col mt-8 mb-11 text-center max-w-160">
            <h1 className={"text-heading-5 font-bold mb-4"}>
                {t("perks.header.header")}
            </h1>
            <div className='w-[90%]'>
                <h2 className={"text-primary-gray-200 text-heading-7 leading-6"}>
                    {t("perks.header.subHeaderfl")}
                </h2>
            </div>
        </header>
    );
};