import React from "react";
import { icons } from "../../assets/icons/icons";

type ButtonProps = {
    type?: string,
    required?: boolean,
    variant: "outlined" | "filled" | "card-outlined";
    size: "small" | "medium" | "large";
    backIcon?: boolean;
    forwardIcon?: boolean;
    onClick?: () => void;
    title: string;
    buttonBold?: string;
    classes?: string;
    shadow?: boolean;
};

interface ButtonStyles {
    color: string;
    outline: string;
    cursor: string;
    marginBottom: string;
    display: string;
    alignItems: string;
    iconStyle?: React.CSSProperties;
    boxShadow?: string;
}

export const Button: React.FC<ButtonProps> = ({
    variant,
    size,
    backIcon,
    forwardIcon,
    onClick,
    title,
    buttonBold,
    classes,
    shadow
}) => {
    const getButtonStyles = (): ButtonStyles => {
        let styles: ButtonStyles = { ...buttonStyles.base };

        // Variant styles
        if (variant === "outlined") {
            styles = { ...styles, ...buttonStyles.outlined };
        } else if (variant === "filled") {
            styles = { ...styles, ...buttonStyles.filled };
        }

        if (shadow) {
            styles = { ...styles, boxShadow: shadow ? '5px 4px 13px 0px #00000033' : '' }
        }

        // Size styles
        if (size === "small") {
            styles = { ...styles, ...buttonStyles.small };
        } else if (size === "medium") {
            styles = { ...styles, ...buttonStyles.medium };
        } else if (size === "large") {
            styles = { ...styles, ...buttonStyles.large };
        }

        return styles;
    };

    return (
        <button style={getButtonStyles()} onClick={onClick} className={classes}>
            {backIcon && (
                <img
                    src={icons.button.arrow}
                    alt={"arrow"}
                    style={{
                        ...buttonStyles.iconBase.backIcon,
                        ...(backIcon && { transform: "rotate(180deg)" }),
                        ...getButtonStyles().iconStyle,
                    }}
                />
            )}
            {title}
            {buttonBold && (
                <>{" "}<strong style={{ fontWeight: "bold", paddingLeft: "10px" }}>
                    {buttonBold}
                </strong></>)}
            {forwardIcon && (
                <img
                    src={icons.button.arrow}
                    style={{
                        ...buttonStyles.iconBase.forwardIcon,
                        ...getButtonStyles().iconStyle,
                    }}
                    alt={"arrow"}
                />
            )}
        </button>
    );
};

const buttonStyles = {
    base: {
        color: "white",
        outline: "none",
        cursor: "pointer",
        marginBottom: "0.5rem",
        display: "flex",
        alignItems: "center",
        whiteSpace: 'nowrap'
    },
    iconBase: {
        forwardIcon: { marginLeft: "0.5rem" },
        backIcon: { marginRight: "0.5rem" },
    },
    outlined: {
        backgroundColor: "transparent",
        border: "1px solid #E8D9F5",
    },
    filled: {
        backgroundColor: "#8a48b5",
        border: "1px solid #8a48b5",
    },
    small: {
        padding: "0.4rem 1rem",
        fontSize: "0.75rem",
        iconStyle: { height: "0.75rem" },
    },
    medium: {
        padding: "1rem 2rem",
        fontSize: "1rem",
        iconStyle: { height: "1rem" },

    },
    large: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        padding: "1rem 1.7rem",
        fontSize: "1rem",
        iconStyle: { height: "1.25rem" },
    },
};
