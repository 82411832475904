import React from "react";
import {Button} from "../../global-components/button/button";
import {useTranslation} from "react-i18next";
import {Header} from "./components/header";
import { Strips } from "./components/strips/strips";


export const Benefits = () => {
    const {t} = useTranslation()

    const handleRedirect = () => {
        window.open('https://challengesoft.tech/contact-us', '_blank');
    };

    return (
        <div id='otherServices' className="px-4 md:px-0 w-full flex flex-col overflow-hidden items-center bg-black relative ">
            <Header/>
            <div>
                <Button
                    forwardIcon={true}
                    variant={"outlined"}
                    size={"large"}
                    onClick={handleRedirect}
                    title={t("benefits.button")}
                    buttonBold={t("benefits.buttonBold")}
                />
            </div>
            <Strips/>
        </div>
    );
};
