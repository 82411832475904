import React from "react";

interface HeaderNavButtonProps {
    title: string;
    link: string | (() => void);
    isActive: boolean;
    setActiveLink: React.Dispatch<React.SetStateAction<string>>;
    toggleMenu?: () => void;
}

export const HeaderNavButton: React.FC<HeaderNavButtonProps> = ({
    title,
    link,
    isActive,
    setActiveLink,
    toggleMenu
}) => {
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();

        // If `link` is a function, execute it
        if (typeof link === "function") {
            link(); // Call the function passed as `link`
        } else {
            // If `link` is a string, proceed with the original logic
            setActiveLink(link);

            if (link.startsWith("/#")) {
                // Scroll to section
                const targetElement: HTMLElement | null = document.querySelector(link.replace("/#", "#"));
                if (targetElement) {
                    if (toggleMenu) toggleMenu()
                    setTimeout(() => {
                        const elementPosition = targetElement.offsetTop;
                        const offsetPosition = elementPosition - 100;
                        console.log(targetElement, offsetPosition)
                        window.scrollTo({
                            top: offsetPosition,
                            behavior: "smooth"
                        });

                        // Update the URL without reloading
                        window.history.pushState(null, "", link);
                    }, 0);
                }
            } else {
                // Navigate to the link
                window.location.href = link;
            }
        }

        setTimeout(() => {
            if (toggleMenu) toggleMenu();
        }, 1600);
    };

    return (
        <a
            className={`w-fit relative text-white underline-nav-item text-sm p-1 mr-4 cursor-pointer ${isActive ? "active" : ""
                }`}
            onClick={handleClick}
            href={typeof link === "string" ? link : "#"}
        >
            {title}
        </a>
    );
};
