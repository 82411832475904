// src/index.tsx
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import i18n from './i18n/i18n';
import {I18nextProvider} from 'react-i18next';
import './index.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {ContactUsPage} from "./sections/contact-us-page/contact-us-page";
import ReactGA from 'react-ga4';

ReactGA.initialize('G-1KLM5Q45K7');

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<App/>}/>
                    <Route path='/contact-us-page' element={<ContactUsPage />} />
                </Routes>
            </BrowserRouter>
        </I18nextProvider>
    </React.StrictMode>
);
