import React from "react";
import { Header } from "./components/header";
import { Button } from "../../global-components/button/button";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

import { useState } from "react";
import { bgImages } from "../../assets/images/bg-images";

type Plan = {
    title: string;
    price: string;
    subtitle: string;
    features: string[];
    description: string;
};

const plans: { [key: string]: Plan } = {
    "Smart Start": {
        title: "Smart Start",
        price: "$299.00",
        subtitle: "Logo Design",
        description: "Includes basic logo design package.",
        features: ["Logo design", "Color palette"],
    },
    "Power Boost": {
        title: "Power Boost",
        price: "$599.00",
        subtitle: "Standard Brand Design",
        description: "Everything in Smart Start, plus:",
        features: ["Logo design", "Color palette", "Business card"],
    },
    "Maximum Potential": {
        title: "Maximum Potential",
        price: "$1199.00",
        subtitle: "Professional Brand Design",
        description: `All the goodness of Power Boost \n + Brand style guide:`,
        features: [
            "Print identity package",
            "Usage mockups",
            "Stationery",
            "Social media graphics",
            "Brand icons",
            "Pattern",
        ],
    },
};

const MobilePlanCard: React.FC<{ plan: Plan }> = ({ plan }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className="bg-gray-900 text-white p-6 border-gradient max-w-[440px] mx-auto w-full">
            <div className="flex flex-col items-center gap-y-4 mb-4">
                <div className="text-[26px] font-prosto-one leading-none">
                    {plan.price} <span className="text-sm font-exo-2">USD</span>
                </div>
                <h3 className="text-purple-500 text-heading-8 text-primary-purple-500-90%">{plan.subtitle}</h3>
                <p className="text-heading-6 font-prosto-one">{plan.title}</p>
            </div>

            {isExpanded && (
                <div className="w-full">
                    <div className="text-left mb-4">
                        {plan.description.split('\n').map((line, i) => (
                            <div className="leading-normal" key={i}>{line}</div>
                        ))}
                    </div>
                    <ul className="list-none mb-4">
                        {plan.features.map((feature, index) => (
                            <li key={index} className="flex items-center text-xsm mb-4">
                                <span className="mr-2">
                                    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="#8A48B5" fillRule="evenodd" clipRule="evenodd" d="M12.8481 0.580078C12.9927 0.744076 13.074 0.966474 13.074 1.19837C13.074 1.43026 12.9927 1.65266 12.8481 1.81665L5.48608 10.1738C5.34142 10.3378 5.14524 10.4299 4.94069 10.4299C4.73614 10.4299 4.53996 10.3378 4.3953 10.1738L0.538237 5.80122C0.46456 5.72055 0.405792 5.62405 0.365363 5.51736C0.324934 5.41066 0.303654 5.29591 0.302764 5.17979C0.301873 5.06367 0.321391 4.94851 0.360179 4.84104C0.398966 4.73356 0.456246 4.63592 0.528676 4.55381C0.601106 4.4717 0.687235 4.40676 0.782039 4.36279C0.876843 4.31882 0.978422 4.29669 1.08085 4.2977C1.18328 4.29871 1.2845 4.32283 1.37862 4.36867C1.47273 4.4145 1.55785 4.48112 1.62901 4.56465L4.94069 8.31897L11.7573 0.580078C11.902 0.416131 12.0982 0.32403 12.3027 0.32403C12.5073 0.32403 12.7035 0.416131 12.8481 0.580078Z" />
                                    </svg>
                                </span>
                                {feature}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <Button
                variant="filled"
                size="medium"
                title="Get Started"
                classes="my-0 w-full flex flex-col items-center font-regular !mb-4 max-w-[340px] mx-auto"
            />
            <button
                className="w-full text-center text-purple-500 font-regular"
                onClick={() => setIsExpanded(!isExpanded)}
            >
                {isExpanded ? 'Show Less' : 'More Details'}
            </button>
        </div>
    );
};

export const PricingSection: React.FC = () => {
    const [activePlan, setActivePlan] = useState<Plan>(plans["Maximum Potential"]);

    return (
        <>
            {/* Desktop/Tablet Layout */}
            <div className="hidden md:grid min-h-[506px] w-full max-w-300 mx-auto px-4 pt-7 md:pt-14 pb-8 grid-cols-1 md:grid-cols-[1.426fr_320px] lg:grid-cols-[1.426fr_1fr] gap-7.5 h--[740px]">
                <div className="h-full">
                    <div className="h-full bg-gray-900 text-white px-6 md:px-14 lg:px-25 py-8 md:py-10 lg:py-14 border border-primary-purple-desaturated relative background-soft-floater bg-fuzz-100 flex flex-col">
                        <h2 className="text-heading-6 font-semibold mb-4 font-prosto-one leading-primary">{activePlan.title}</h2>
                        <div className="mb-6 text-left">
                            {activePlan.description.split('\n').map((line, i) => (
                                <div key={i}>{line}</div>
                            ))}
                        </div>
                        <ul className="list-none mb-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
                            {activePlan.features.map((feature, index) => (
                                <li key={index} className="flex items-center text-xsm whitespace-nowrap">
                                    <span className="mr-2">
                                        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill="#8A48B5" fillRule="evenodd" clipRule="evenodd" d="M12.8481 0.580078C12.9927 0.744076 13.074 0.966474 13.074 1.19837C13.074 1.43026 12.9927 1.65266 12.8481 1.81665L5.48608 10.1738C5.34142 10.3378 5.14524 10.4299 4.94069 10.4299C4.73614 10.4299 4.53996 10.3378 4.3953 10.1738L0.538237 5.80122C0.46456 5.72055 0.405792 5.62405 0.365363 5.51736C0.324934 5.41066 0.303654 5.29591 0.302764 5.17979C0.301873 5.06367 0.321391 4.94851 0.360179 4.84104C0.398966 4.73356 0.456246 4.63592 0.528676 4.55381C0.601106 4.4717 0.687235 4.40676 0.782039 4.36279C0.876843 4.31882 0.978422 4.29669 1.08085 4.2977C1.18328 4.29871 1.2845 4.32283 1.37862 4.36867C1.47273 4.4145 1.55785 4.48112 1.62901 4.56465L4.94069 8.31897L11.7573 0.580078C11.902 0.416131 12.0982 0.32403 12.3027 0.32403C12.5073 0.32403 12.7035 0.416131 12.8481 0.580078Z" />
                                        </svg>
                                    </span> {feature}
                                </li>
                            ))}
                        </ul>
                        <button className="bg-primary-purple-700 text-white font-semibold py-2 px-6 w-full mt-auto mb-0">
                            Buy
                        </button>
                    </div>
                </div>

                {/* Right Tabs Section */}
                <div className="h-full grid grid-rows-3 gap-7.5">
                    {Object.values(plans).map((plan) => (
                        <div
                            key={plan.title}
                            className={`bg-[100%] py-6 px-10 rounded-lg border-gradient cursor-pointer transition duration-200 wide-tablet:flex wide-tablet:flex-row wide-tablet:justify-between md:flex md:flex-col-reverse md:items-start flex flex-row justify-between gap-4 ${activePlan.title === plan.title
                                ? "bg-fuzz-100 active-plan"
                                : ""
                                }`}
                            onClick={() => setActivePlan(plan)}
                        >
                            <div style={{ backgroundImage: `url(${bgImages.bgBlob})` }} className="background-blob"></div>
                            <div className="flex flex-col gap-4">
                                <h3 className="text-purple-500 text-left text-heading-8 text-primary-purple-500-90% whitespace-nowrap">{plan.subtitle}</h3>
                                <p className="text-lg text-white text-left font-prosto-one whitespace-nowrap">{plan.title}</p>
                            </div>
                            <div className="font-bold text-right text-white text-[30px] pt-[5px] font-prosto-one leading-none">{plan.price} <div className="text-sm pt-[5px] md:inline md:relative md:left-[-4px] font-exo-2">USD</div></div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Mobile Layout */}
            <div className="md:hidden w-full flex flex-col gap-4 px-4 py-8">
                {Object.values(plans).map((plan) => (
                    <MobilePlanCard key={plan.title} plan={plan} />
                ))}
            </div>
        </>
    );
};

export const Pricing = () => {
    const navigate = useNavigate();
    const handleRedirect = () => {
        navigate('/contact-us-page');
        window.scrollTo(0, 0);
    };
    const { t } = useTranslation();
    return (
        <div id='pricing' className='items-center flex flex-col'>
            <Header />
            <PricingSection />
            <div className='my-8 md:my-19 lg:my-23 px-4 md:px-0'>
                <Button variant={"outlined"} size={"large"} onClick={handleRedirect} title={t("pricing.buttonText")} buttonBold={t("pricing.buttonBold")} forwardIcon />
            </div>
        </div>
    );
}