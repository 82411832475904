import React from 'react';
import {Navigation} from "../header/components/navigation";
import {ArtChallengeLogo} from "../../assets/images/common/art-main-logo/art-challenge-logo";
import {icons} from "../../assets/icons/icons";
import {useTranslation} from "react-i18next";

export const Footer = () => {

    const {t} = useTranslation();

    const socialMediaIcons = [
        { name: 'LinkedIn', icon: icons.footer.linkedIn, link: 'https://www.linkedin.com/company/challengesoft/' },
        { name: 'Mail', icon: icons.footer.mail, link: 'mailto:team@challengesoft.tech' },
        { name: 'WhatsApp', icon: icons.footer.whatsApp, link: 'https://api.whatsapp.com/send?phone=12064959472' }
    ];


    return (
        <footer className='mt-[120px] w-screen bg-primary-gray-1000 flex flex-col items-center'>
            <div className='pt-8 pb-6 flex flex-row items-center justify-around w-full'>
                <ArtChallengeLogo/>
                <div className="hidden
                md:hidden
                lg:block
                xl:block">
                    <Navigation/>
                </div>
                <div className='flex flex-row gap-2 cursor-pointer'>
                    {socialMediaIcons.map((socialMedia, index) => (
                        <a key={index} href={socialMedia.link} target='_blank' rel="noopener noreferrer">
                            <img
                                key={index}
                                src={socialMedia.icon}
                                alt={socialMedia.name}
                                className='h-[30px] w-[30px]'
                            />
                        </a>
                    ))}
                </div>
            </div>
            <div className='bg-primary-gray-1100 opacity-10 h-[1px] w-[80%]'/>
            <div className='text-center my-[30px]'>
                © {(new Date()).getFullYear()} {t("footer.copyright")}
            </div>
        </footer>
    )
}
