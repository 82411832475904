import React from "react";
// @ts-expect-error //logo is propery imported
import artChallengeLogo from "./art-challenge.png";
import { useNavigate } from "react-router-dom";


export const ArtChallengeLogo = () => {
    const navigate = useNavigate();
    return (
        <>
            <span className="cursor-pointer" onClick={() => {
                navigate(`/`);
                window.scrollTo(0, 0)
            }}>
                <img src={artChallengeLogo} alt="Logo" className="w-[77px] cursor-pointer"/>
            </span>
        </>
    );
};
